import { Ask } from "./Ask";

type Result = string;

export const AskPodcast = () => {
  return (
    <Ask<Result>
      pills={[
        "Core Web Vitals",
        "JavaScript",
        "Work/Life Balance",
        "SolidJS Ecosystem",
      ]}
      id="ask-podcast"
      placeholders={[
        "How do I be more productive?",
        "Should I choose Vercel or Netlify?",
        "What's the role of Sentry in applications?",
        "What makes the web epic?",
        "What's the best framework for web apps today?",
      ]}
      apiRoute="askPodcast"
      stream
    ></Ask>
  );
};
